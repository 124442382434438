import { useState } from "react"
import { prodplanstatus, productUnit } from "../Maps"
import { NumericFormat } from "react-number-format"
import { navigate } from "gatsby"
import { PenIcon } from "../../../../Graphics/IconsMyPage"
import ConditionsModal from "../../Modal/ConditionsModal"

const ProductionPlansTableContent = ({ prodPlan, plans, key }) => {
  const [conditionId, setConditionId] = useState("")
  let terms = false

  if (prodPlan?.customer?.hasYearTerms === true && prodPlan?.planProduct?.numberOfYearTerms > 0) {
    terms = true
  }

  const handleClick = () => {
    if (
      prodPlan?.customer?.hasYearTerms === false ||
      (prodPlan?.customer?.hasYearTerms === true && prodPlan?.planProduct?.numberOfYearTerms === 0) ||
      prodPlan?.yearTermsConfirmed === true
    ) {
      if (prodPlan.lastYearPlanId === undefined) {
        navigate(
          "/minside/produksjonsplaner/plan?id=" +
            prodPlan?.id +
            "&unit=" +
            prodPlan?.planProduct?.planProductUnit +
            "&customer=" +
            prodPlan?.customer?.customerName +
            "&product=" +
            prodPlan?.planProduct?.planProductName +
            "&sum=" +
            prodPlan?.sumQuantity +
            "&totalSum=" +
            plans?.prodPlan.map((prodPlan) => prodPlan.sumQuantity).reduce((acc, cur) => acc + cur) +
            "&c=" +
            prodPlan?.customer?.companyId +
            "&pp=" +
            prodPlan?.planProduct?.id +
            "&terms=" +
            terms
        )
      } else {
        navigate(
          "/minside/produksjonsplaner/plan?id=" +
            prodPlan?.id +
            "&lastPlan=" +
            prodPlan?.lastYearPlanId +
            "&unit=" +
            prodPlan?.planProduct?.planProductUnit +
            "&customer=" +
            prodPlan?.customer?.customerName +
            "&product=" +
            prodPlan?.planProduct?.planProductName +
            "&sum=" +
            prodPlan?.sumQuantity +
            "&totalSum=" +
            plans?.prodPlan.map((prodPlan) => prodPlan.sumQuantity).reduce((acc, cur) => acc + cur) +
            "&c=" +
            prodPlan?.customer?.companyId +
            "&pp=" +
            prodPlan?.planProduct?.id +
            "&terms=" +
            terms
        )
      }
    } else {
      setConditionId(prodPlan?.id)
    }
  }

  let typeDesign = " bg-none "

  if (prodplanstatus.get(prodPlan?.prodPlanStatus) === "Klar til godkjenning") {
    typeDesign = " bg-behandles "
  } else if (prodplanstatus.get(prodPlan?.prodPlanStatus) === "Godkjent") {
    typeDesign = " bg-godkjent "
  } else {
    typeDesign = " bg-avvist "
  }

  return (
    <>
      {prodPlan?.id === conditionId ? (
        <ConditionsModal
          prodPlan={prodPlan}
          totalSum={plans?.prodPlan.map((prodPlan) => prodPlan.sumQuantity).reduce((acc, cur) => acc + cur)}
          setConditionId={setConditionId}
        />
      ) : null}
      <tr key={key} className=" text-xs sm:text-base  ">
        <td className=" pl-2 sm:pl-10 py-4 border-b border-grey uppercase">{prodPlan?.customer?.customerName}</td>
        {/* Last year */}
        <td className=" hidden min-[355px]:table-cell border-b border-grey text-center text-ghGreen-600 ">
          <NumericFormat displayType="text" value={prodPlan?.lastYearPlan} thousandSeparator={" "} />{" "}
          {productUnit.get(prodPlan?.planProduct?.planProductUnit)}
        </td>
        {/* This year */}
        <td className="  text-center  border-b border-grey ">
          <NumericFormat displayType="text" value={prodPlan?.sumQuantity} thousandSeparator={" "} />{" "}
          {productUnit.get(prodPlan?.planProduct?.planProductUnit)}
        </td>
        {/* This status */}
        <td className="  text-center border-b border-grey ">
          <p className={" text-xs min-[900px]:text-base rounded-lg bg-opacity-60 bg-" + typeDesign}>
            {prodplanstatus.get(prodPlan?.prodPlanStatus)}
          </p>
        </td>
        {/* Edit button  */}
        <button
          onClick={handleClick}
          className=" mt-2 sm:mt-3 md:mt-3 md:py-0.5 px-1 ml-1 md:ml-4 md:flex md:flex-row md:justify-center md:items-center border-2 rounded-lg border-grey hover:border-black active:bg-disabled disabled:bg-disabled disabled:text-white focus:outline-none focus:ring-0 focus:border-3 focus:border-black "
        >
          <PenIcon />
          <span className=" hidden lg:flex sm:ml-2 ">Se plan</span>
        </button>
      </tr>
    </>
  )
}

export default ProductionPlansTableContent
