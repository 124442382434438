import { useAccount, useMsal } from "@azure/msal-react"
import { useMutation, useQuery, QueryClient } from "react-query"
import { useState } from "react"
import { NumericFormat } from "react-number-format"
import { fetchApi, fetchApiDownload, patchApi } from "../../../../Common/WebApi-utils"
import { month, productUnit } from "../Maps"
import { greenAddButton, greenSaveButton, redButton } from "../../../../Styling/ButtonStyles"
import { navigate } from "gatsby"
import LastSaved from "../LastSavedComponent"
import NotificationAlert from "../../../../Graphics/NotificationAlert"
import { GetProfile } from "../ReactQueries"

const queryClient = new QueryClient()

type Answer = {
  Id: string
  ProdPlanStatus: number
  ProdPlanCommentPlan: string
}

type Comment = {
  Id: string
  ProdPlanCommentPlan: string
}

const AcceptPlansComponent = ({
  prodPlanId,
  lastYearPlanId,
  setLoadingDone,
  product,
  customer,
  sum,
  totalSum,
  unit,
  companyId,
  planProductId,
  hasTerms,
}) => {
  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})
  const userProfile = GetProfile(accounts, inProgress, instance)
  const [comment, setComment] = useState("")
  const [commentSubmitted, setCommentSubmitted] = useState(false)
  const [answerSubmitted, setAnswerSubmitted] = useState(false)
  const [commentSaved, setCommentSaved] = useState(false)
  const [hasComment, setHasComment] = useState(true)
  const [alert, setAlert] = useState(false)
  const [alertType, setAlertType] = useState("")
  const [alertMessage, setAlertMessage] = useState("")
  const [alertUnderlineMessage, setAlertUnderlineMessage] = useState("")
  const [showTerms, setShowTerms] = useState(false)
  const [showButtons, setShowButtons] = useState(false)

  const currentDate = new Date(userProfile?.data?.today)
  const seasonChange = new Date(currentDate.getFullYear().toString() + "-10-15")
  const thisYear = currentDate > seasonChange ? currentDate.getFullYear() + 1 : currentDate.getFullYear()
  const thisYearString = thisYear.toString()
  const nextYearString = (thisYear + 1).toString().slice(2)
  const thisYearNumber = thisYear
  const lastYearNumber = thisYear - 1
  const [savedDate, setSavedDate] = useState(new Date())
  const [downloading, setDownloading] = useState(false)
  let query = ""

  if (lastYearPlanId === undefined) {
    query = "/PlanWeek/ProdPlanWeeks?prodPlanId=" + prodPlanId
  } else {
    query = "/PlanWeek/ProdPlanWeeks?prodPlanId=" + prodPlanId + "&lastYearPlanId=" + lastYearPlanId
  }

  const getMyPlanWeeks = useQuery(
    "myPlansWeekData",
    () => fetchApi(process.env.REACT_APP_API_URL_APIM + query, account, inProgress, instance).then((res) => res.data),
    {
      onSuccess: (data) => {
        setComment(data?.whishes?.[0]?.planWeeks?.[0]?.prodPlanNo?.prodPlanCommentPlan)
        if (data?.whishes?.[0]?.planWeeks?.[0]?.prodPlanNo?.prodPlanCommentPlan === undefined) {
          setHasComment(false)
        }
        // If the plan is 292460002/ "Klar til godkjenning" show the "Godkjenn" and "Avvis" buttons
        if (data?.whishes?.[0]?.planWeeks?.[0]?.prodPlanNo?.prodPlanStatus === 292460002) {
          setShowButtons(true)
        }
        setLoadingDone(true)
      },
      onError: (_error) => {
        // error,message
      },
      refetchOnWindowFocus: false,
    }
  )

  const getYearTermsDocument = useQuery(
    "myYearTermsDocument",
    () =>
      fetchApi(
        process.env.REACT_APP_API_URL_APIM +
          "/Documents/YearTerms?planProductId=" +
          planProductId +
          "&accountId=" +
          companyId,
        account,
        inProgress,
        instance
      ).then((res) => res.data),
    {
      enabled: hasTerms === "true",
      onSuccess: (data) => {
        if (data?.downloadUrl !== undefined) {
          setShowTerms(true)
        }
      },
    }
  )

  const mutationDocument = useMutation(
    () =>
      fetchApiDownload(
        process.env.REACT_APP_API_URL_APIM + "/Documents/Download/" + getYearTermsDocument?.data?.blobId,
        account,
        inProgress,
        instance
      ).then((res) => res.data),
    {
      onSuccess: (blob: any) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]))
        const link = document.createElement("a")
        link.href = url
        link.setAttribute("download", getYearTermsDocument?.data?.title)

        // Append to html link element page
        document.body.appendChild(link)

        // Start download
        link.click()

        // Clean up and remove the link
        if (link.parentNode) {
          link.parentNode.removeChild(link)
        }

        setDownloading(false)
      },
      onError: (_error) => {
        setAlert(true)
        setAlertType("error")
        setAlertMessage("Det har skjedd en feil. Prøv igjen senere")
        setAlertUnderlineMessage("")
        setDownloading(false)
      },
    }
  )

  const patchAnswer = useMutation(
    async (values) =>
      patchApi(process.env.REACT_APP_API_URL_APIM + "/ProductionPlan", values, account, inProgress, instance).then(
        (res) => res.data
      ),
    {
      onError: (_error) => {
        setAlert(true)
        setAlertType("error")
        setAlertMessage("Det har skjedd en feil. Prøv igjen senere")
        setAlertUnderlineMessage("")
        setLoadingDone(true)
      },
      onSuccess: () => {
        navigate("/minside/produksjonsplaner")
      },
    }
  )

  // If the answer is yes
  const approve = () => {
    setCommentSubmitted(false)
    const answerObject: Answer = {
      Id: prodPlanId,
      ProdPlanStatus: 292460003,
      ProdPlanCommentPlan: comment,
    }
    setLoadingDone(false)
    setAnswerSubmitted(true)
    patchAnswer.mutate(answerObject)
  }

  // If the answer is no
  const decline = () => {
    const answerObject: Answer = {
      Id: prodPlanId,
      ProdPlanStatus: 292460004,
      ProdPlanCommentPlan: comment,
    }
    setLoadingDone(false)
    setAnswerSubmitted(true)
    patchAnswer.mutate(answerObject)
  }

  const patchComment = useMutation(
    async (values) =>
      patchApi(process.env.REACT_APP_API_URL_APIM + "/ProductionPlan", values, account, inProgress, instance).then(
        (res) => res.data
      ),
    {
      onError: (_error) => {
        setAlert(true)
        setAlertType("error")
        setAlertMessage("Det har skjedd en feil. Prøv igjen senere")
        setAlertUnderlineMessage("")
        setLoadingDone(true)
      },
      onSuccess: () => {
        queryClient.invalidateQueries("myPlansWeekData")
        setHasComment(true)
        setCommentSaved(true)
        setLoadingDone(true)
      },
    }
  )

  const submitComment = () => {
    const commentObject: Comment = {
      Id: prodPlanId,
      ProdPlanCommentPlan: comment,
    }
    patchComment.mutate(commentObject)
    setSavedDate(new Date())
    setLoadingDone(false)
    setCommentSubmitted(true)
  }

  const handleComment = (e) => {
    setComment(e.target.value)
  }

  const downloadFile = () => {
    mutationDocument.mutate()
    setDownloading(true)
  }

  const plansArray = getMyPlanWeeks?.data?.plans

  return (
    <>
      <div>
        {alert ? (
          <NotificationAlert
            alert={alert}
            setAlert={setAlert}
            type={alertType}
            message={alertMessage}
            underlineMessage={alertUnderlineMessage}
          />
        ) : null}
      </div>
      {/* Component */}
      <div className=" flex flex-col items-center lg:items-stretch lg:flex-row lg:space-x-8 lg:appearance-none overflow-y-auto mb-12 ">
        {/* Last year plan og this year plan week information */}
        <div className="flex flex-col ">
          <div className=" grid-cols-3 mb-4 grid sm:grid-cols-4 h-16">
            <div className=" hidden sm:flex bg-ghGreen-100 sm:bg-white"></div>
            <div className=" pl-4 sm:pl-0 flex justify-center items-center bg-ghGreen-100 h-16 ">Uke</div>
            <div className=" flex justify-center items-center bg-ghGreen-100 h-16 text-ghGreen-600 ">
              Plan {lastYearNumber}
            </div>
            <div className="flex justify-center items-center bg-ghGreen-100 h-16">Plan {thisYearNumber}</div>
          </div>
          {/* Table content */}
          <div className="flex flex-row w-full">
            <div className="flex flex-col">
              {getMyPlanWeeks?.data?.whishes.map((prop, index) => (
                <div key={index} className="flex flex-col sm:flex-row">
                  <p className=" w-36 mt-5 ">
                    {month.get(prop.month)} {prop.year}
                  </p>
                  <ul>
                    {prop.planWeeks.map((planWeekProp, i) => {
                      const qty =
                        lastYearPlanId !== undefined
                          ? plansArray?.find((item) => item.weekNumber === planWeekProp.planWeekNo)
                          : null

                      return (
                        <li key={i} className=" grid grid-cols-3 ">
                          <div className=" pl-2 sm:pl-0 flex justify-start sm:justify-center items-center border-b-2 border-black ">
                            <p className=" flex items-center justify-center appearance-none w-36 h-11 my-3 text-center">
                              {planWeekProp?.planWeekNo?.toString().slice(-2) < 10
                                ? planWeekProp?.planWeekNo?.toString().slice(-1)
                                : planWeekProp?.planWeekNo?.toString().slice(-2)}
                            </p>
                          </div>
                          {/* show on bigger than sm screen */}
                          <div className=" flex justify-center items-center pl-2 text-ghGreen-500 border-b-2 border-black ">
                            <p>
                              <NumericFormat
                                displayType="text"
                                value={lastYearPlanId !== undefined ? qty?.quantity : 0}
                                thousandSeparator={" "}
                              />{" "}
                              {productUnit.get(unit)}
                            </p>
                          </div>
                          {/* show on smaller than sm screen */}
                          <div className=" flex pr-4 justify-center items-center border-b-2 border-black ">
                            <p>
                              <NumericFormat
                                displayType="text"
                                value={planWeekProp?.planWeekQuantity}
                                thousandSeparator=" "
                              />{" "}
                              {productUnit.get(unit)}
                            </p>
                          </div>
                        </li>
                      )
                    })}
                  </ul>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* Product information side content */}
        <div className=" mb-4 flex flex-col w-full sm:w-2/3 lg:self-start mt-8 lg:w-1/3 lg:mt-0 lg:overflow-y-auto lg:sticky lg:top-0 lg:right-0">
          {/* Product and year */}
          <div className=" flex h-16 justify-center items-center bg-ghGreen-100 text-center uppercase font-semibold mb-2">
            {product} {thisYearString}/{nextYearString}
          </div>
          {/* Sum information */}
          <div className=" flex ">
            <div className=" pl-4 w-full border-r border-b border-grey">
              Til {customer}
              <p className="mt-2 mb-4 text-base font-semibold">
                <NumericFormat displayType="text" value={sum} thousandSeparator={" "} /> {productUnit.get(unit)}
              </p>
            </div>
            <div className=" pl-4 w-full border-b border-grey">
              Sum total
              <p className="mt-2 mb-4 text-base font-semibold ">
                <NumericFormat displayType="text" value={totalSum} thousandSeparator={" "} /> {productUnit.get(unit)}
              </p>
            </div>
          </div>
          {/* Accept/decline plan buttons */}
          {showButtons ? (
            <div className=" flex flex-row space-x-14 my-8 justify-evenly  ">
              <button className={greenAddButton} onClick={approve}>
                Godkjenn
              </button>
              <button className={redButton} onClick={decline}>
                Avslå
              </button>
            </div>
          ) : null}
          {/* Download conditions */}
          {showTerms ? (
            <div className=" flex flex-row items-center justify-center mb-4 mt-2 ">
              <>
                {downloading ? (
                  <p>Laster ned...</p>
                ) : (
                  <button onClick={() => downloadFile()} className="underline underline-offset-1 hover:text-grey">
                    Se betingelser
                  </button>
                )}
              </>
            </div>
          ) : null}
          {/* Comment */}
          <div className=" flex flex-col pt-4">
            {hasComment ? (
              <textarea
                maxLength={200}
                defaultValue={comment}
                readOnly
                className=" border-2 border-grey rounded-2xl h-44 focus:ring-black focus:border-black"
              ></textarea>
            ) : (
              <textarea
                placeholder="Her kan man skrive en tilbakemelding til Gartnerhallens produksjonsplanleggere"
                maxLength={200}
                defaultValue={comment}
                onChange={handleComment}
                className=" border-2 border-grey rounded-2xl h-44 focus:ring-black focus:border-black"
              ></textarea>
            )}
          </div>
          {/* Submit comment */}
          {hasComment === false ? (
            <div className=" text-right mt-8 ">
              <button onClick={submitComment} className={greenSaveButton + " mb-4 "}>
                Send kommentar
              </button>
            </div>
          ) : null}
          {/* Show message if the user have an active comment on the plan */}
          {hasComment ? (
            <div className=" mt-4 ">
              <p className=" text-left text-sm ">Videre kommunikasjon må skje på e-post</p>
            </div>
          ) : null}
          {/* Show if user just saved the comment */}
          <div className=" text-right mt-4 ">
            {commentSaved ? <LastSaved savedDate={savedDate} placeholder={"Kommentar ble sendt"} /> : null}
          </div>
        </div>
      </div>
    </>
  )
}

export default AcceptPlansComponent
